import ApplicationController from './application_controller';

// Connects to data-controller="drawer"
export default class extends ApplicationController {
  static values = {
    statusCode: Number,
  };

  connect() {
    super.connect();

    document.body.style.overflow = 'hidden';

    if (this.statusCodeValue === 422) {
      this.show();
    } else {
      this.showWithAnimation();
    }
  }

  show() {
    this.element.classList.add('active');
  }

  // Tips: https://developer.mozilla.org/ja/docs/Web/CSS/CSS_animations/Tips
  showWithAnimation() {
    const { element } = this;

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        element.classList.add('active');
      });
    });
  }

  close() {
    document.body.removeAttribute('style');
    this.element.classList.remove('active');
  }

  closeIfSuccess(event) {
    if (event.detail.success) {
      this.close();
    }
  }

  closeByOverlayClick(event) {
    if (event.target.classList.contains('drawer-overlay')) {
      this.close();
    }
  }
}
