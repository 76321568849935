import { Dropzone } from 'dropzone';
import ApplicationController from './application_controller';

// Connects to data-controller="dropzone"
export default class extends ApplicationController {
  connect() {
    super.connect();
    this.dropzone = new Dropzone(this.element, this.dropzoneOptions);
  }

  get dropzoneOptions() {
    return {
      url: this.element.action,
      parallelUploads: 1,
    };
  }
}
