import MicroModal from 'micromodal';
import ApplicationController from './application_controller';

// Connects to data-controller="static-modal"
export default class extends ApplicationController {
  static values = { id: String };

  connect() {
    super.connect();
  }

  show() {
    MicroModal.show(this.idValue, { disableScroll: true });
  }

  close() {
    MicroModal.close(this.idValue);
  }
}
