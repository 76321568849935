import ApplicationController from '../application_controller';

// Connects to data-controller="employees--cookie"
export default class extends ApplicationController {
  static values = {
    url: String,
    model: String,
  };

  connect() {
    super.connect();
  }

  setCookie(event) {
    const body = {};
    body[this.modelValue] = event.params;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
      },
      body: JSON.stringify(body),
    };

    fetch(this.urlValue, options).then();
  }
}
