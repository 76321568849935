import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dropdown-filter"
export default class extends Controller {
  static targets = ['filter', 'dropdown'];

  connect() {
    const triggerEventName = (elm) => {
      const { type } = elm;
      return type === 'radio' ? 'change' : 'click';
    };

    this.filterTargets.forEach((el) => {
      el.addEventListener(triggerEventName(el), this.update.bind(this));
    });

    const selectedFilter = this.filterTargets.find((el) => {
      if (el.type === 'radio') {
        return el.checked;
      }
      return el.querySelector('input[type="radio"]').checked;
    });
    if (selectedFilter) {
      selectedFilter.dispatchEvent(new Event(triggerEventName(selectedFilter)));
    }
  }

  update(event) {
    if (event) {
      const { key, value } = event.currentTarget.dataset;
      this.enableDropdown(key, value);
    } else {
      this.disableDropdown();
    }
  }

  enableDropdown(key, value) {
    this.dropdownTarget.disabled = false;
    this.dropdownTarget.querySelectorAll('option').forEach((option) => {
      if (option.getAttribute(`data-${key}`) === value) {
        option.style.display = 'inherit';
      } else {
        option.removeAttribute('selected');
        option.style.display = 'none';
      }
    });
    if (!this.dropdownTarget.querySelector(`option[selected][data-${key}="${value}"]`)) {
      const { value: firstOptionValue } = this.dropdownTarget.querySelector(`option[data-${key}="${value}"]`);
      this.dropdownTarget.value = firstOptionValue;
      this.dropdownTarget.dispatchEvent(new Event('change'));
    }
  }

  disableDropdown() {
    this.dropdownTarget.disabled = true;
    this.dropdownTarget.value = '';
    this.dropdownTarget.querySelectorAll('option').forEach((option) => {
      option.removeAttribute('selected');
      option.style.display = 'none';
    });
  }
}
