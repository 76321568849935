import Reveal from 'stimulus-reveal-controller';

// Connects to data-controller="accordion"
export default class extends Reveal {
  static targets = ['item', 'icon'];

  toggle() {
    super.toggle();
    this.#toggleIcon();
  }

  show() {
    super.show();
    this.#rotateIcon180deg();
  }

  hide() {
    super.hide();
    this.#rotateIcon0deg();
  }

  #toggleIcon() {
    if (!this.hasIconTarget) { return; }

    if (this.iconTarget.classList.contains('rotate-0')) {
      this.#rotateIcon180deg();
    } else if (this.iconTarget.classList.contains('rotate-180')) {
      this.#rotateIcon0deg();
    }
  }

  #rotateIcon180deg() {
    if (!this.hasIconTarget) { return; }

    this.iconTarget.classList.add('rotate-180');
    this.iconTarget.classList.remove('rotate-0');
  }

  #rotateIcon0deg() {
    if (!this.hasIconTarget) { return; }

    this.iconTarget.classList.remove('rotate-180');
    this.iconTarget.classList.add('rotate-0');
  }
}
