import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="password"
export default class extends Controller {
  static targets = [
    'inputField',
    'iconEye',
    'iconEyeSlash',
  ];

  show() {
    this.inputField.type = 'text';
    this.iconEye.classList.add('d-none');
    this.iconEyeSlash.classList.remove('d-none');
  }

  hide() {
    this.inputField.type = 'password';
    this.iconEye.classList.remove('d-none');
    this.iconEyeSlash.classList.add('d-none');
  }

  get inputField() {
    return this.inputFieldTarget;
  }

  get iconEye() {
    return this.iconEyeTarget;
  }

  get iconEyeSlash() {
    return this.iconEyeSlashTarget;
  }
}
