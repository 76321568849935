import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import ApplicationController from './application_controller';

// Connects to data-controller="datetime-picker"
export default class extends ApplicationController {
  connect() {
    super.connect();

    this.newDatetimePicker();
  }

  newDatetimePicker() {
    const config = {
      enableTime: true,
      locale: Japanese,
    };

    return flatpickr(this.element, config);
  }
}
