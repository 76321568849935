import ApplicationController from './application_controller';

// Connects to data-controller="floating-island"
export default class extends ApplicationController {
  connect() {
    super.connect();
    setTimeout(() => this.popup(), 5000);
  }

  popup() {
    this.element.classList.remove('d-none');
    this.element.classList.add('popup');
  }
}
