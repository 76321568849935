import Tagify from '@yaireo/tagify';

import ApplicationController from './application_controller';

// Connects to data-controller="tag"
export default class extends ApplicationController {
  static targets = ['input', 'content'];

  static values = {
    whitelist: Array,
  };

  connect() {
    super.connect();
    this.contentTagify = this.initTagify();
  }

  initTagify() {
    return new Tagify(this.inputTarget, {
      whitelist: this.whitelistValue,
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(','),
      dropdown: {
        enabled: 0,
      },
    });
  }
}
