import ApplicationController from '../application_controller';

// Connects to data-controller="administrators--newsletter"
export default class extends ApplicationController {
  static targets = ['selectSendBy', 'subject'];

  connect() {
    super.connect();

    this.toggleSubject(this.selectSendBy);
  }

  toggleSubject(element) {
    const selectBox = element.currentTarget ?? element;
    const option = [...selectBox.options].find((o) => o.selected);

    if (option.value === 'email') {
      this.showSubject();
    } else {
      this.hideSubject();
    }
  }

  showSubject() {
    this.subject.classList.remove('d-none');
  }

  hideSubject() {
    this.subject.classList.add('d-none');
  }

  // change(event) {
  //   console.log(event);
  //   console.log(event.currentTarget.selectedOptions[0]);
  //   console.log(event.target);
  //   console.log(event.target.selectedOptions[0].value);
  // }
  //
  // fetchSelectOptions(element) {
  //   const selectBox = element.currentTarget ?? element;
  //   const option = [...selectBox.options].find(option => option.selected);
  //
  //   fetch('')
  //     .then(res => res.json())
  //     .then(json => {
  //       console.log(json);
  //     })
  //     .catch((e) => {
  //       this.handleError(e);
  //     });
  // }

  get selectSendBy() {
    return this.selectSendByTarget;
  }

  get subject() {
    return this.subjectTarget;
  }
}
