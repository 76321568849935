import ApplicationController from './application_controller';

// Connects to data-controller="window"
export default class extends ApplicationController {
  static values = {
    name: { type: String, default: '_blank' },
    width: { type: Number, default: 800 },
    height: { type: Number, default: 500 },
  };

  connect() {
    super.connect();
  }

  open(e) {
    e.preventDefault();

    const top = window.screenTop + (window.innerHeight / 2) - (this.heightValue / 2);
    const left = window.screenLeft + (window.innerWidth / 2) - (this.widthValue / 2);
    const windowFeatures = `height=${this.heightValue},width=${this.widthValue},top=${top},left=${left}`;

    window.open(this.element.href, this.nameValue, windowFeatures);
  }
}
