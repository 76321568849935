import MicroModal from 'micromodal';
import ApplicationController from '../application_controller';

// Connects to data-controller="employees--modal"
export default class extends ApplicationController {
  static values = {
    url: String,
    model: String,
  };

  connect() {
    super.connect();
    this.show();
  }

  show() { // eslint-disable-line class-methods-use-this
    MicroModal.show('modal-window', { disableScroll: true });
  }

  close(event) { // eslint-disable-line class-methods-use-this
    MicroModal.close('modal-window');
    this.#logging(event.params);

    const video = document.getElementById('video');
    if (video !== null) {
      video.pause();
    }
  }

  closeByOverlayClick(event) {
    if (event.target.classList.contains('modal-overlay')) {
      this.close(event);
    }
  }

  closeIfSuccess(event) { // eslint-disable-line class-methods-use-this
    if (event.detail.success) {
      MicroModal.close('modal-window');
    }
  }

  #logging(params) {
    const body = {};
    body[this.modelValue] = params;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
      },
      body: JSON.stringify(body),
    };

    fetch(this.urlValue, options).then();
  }
}
