import * as emoji from 'node-emoji';
import ApplicationController from './application_controller';

// Connects to data-controller="text"
export default class extends ApplicationController {
  static targets = ['input', 'output'];

  connect() {
    super.connect();
  }

  render() {
    this.outputTarget.innerText = emoji.emojify(this.inputTarget.value);
  }
}
