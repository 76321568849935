import { Notyf } from 'notyf';
import ApplicationController from './application_controller';

// Connects to data-controller="toast"
export default class extends ApplicationController {
  static values = {
    type: String,
    message: String,
  };

  connect() {
    super.connect();
    this.show();
  }

  show() {
    const options = {
      duration: 4000,
      ripple: true,
      position: {
        x: 'right',
        y: 'top',
      },
      dismissible: true,
      types: [
        {
          type: 'info',
          className: 'notyf__toast--info',
          backgroundColor: '#007ad8',
          icon: {
            className: 'notyf__icon--success',
            tagName: 'i',
          },
        },
        {
          type: 'warning',
          className: 'notyf__toast--warning',
          backgroundColor: '#edb100',
          icon: {
            className: 'notyf__icon--error',
            tagName: 'i',
          },
        },
      ],
    };
    const notyf = new Notyf(options);

    if (this.type === 'success') {
      notyf.success(this.message);
    }

    if (this.type === 'error') {
      notyf.error(this.message);
    }

    if (this.type === 'info') {
      notyf.open({ type: 'info', message: this.message });
    }

    if (this.type === 'warning') {
      notyf.open({ type: 'warning', message: this.message });
    }
  }

  get type() {
    return this.typeValue;
  }

  get message() {
    return this.messageValue;
  }
}
