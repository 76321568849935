import Chart from 'chart.js/auto';
import ApplicationController from './application_controller';

// Connects to data-controller="chart"
export default class extends ApplicationController {
  static values = {
    type: String,
    data: String,
  };

  connect() {
    super.connect();
    this.show();
  }

  show() {
    new Chart(this.element, {
      type: this.typeValue,
      data: JSON.parse(this.dataValue),
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
}
