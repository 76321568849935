import party from 'party-js';
import ApplicationController from './application_controller';

// Connects to data-controller="party"
export default class extends ApplicationController {
  connect() {
    super.connect();
    this.show();
  }

  show() {
    party.confetti(this.element, { count: party.variation.range(60, 60) });
  }
}
