import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="delivery-day-prompt"
export default class extends Controller {
  static targets = ['daySelect', 'prompt'];

  static values = {
    url: String,
  };

  connect() {
    this.daySelectTarget.addEventListener('change', this.dayChanged.bind(this));
  }

  dayChanged() {
    const url = decodeURI(this.urlValue);
    const urlWithParams = url.replace('%days%', this.daySelectTarget.value);

    fetch(encodeURI(urlWithParams))
      .then((response) => response.json())
      .then((jsonData) => {
        this.promptTarget.innerText = jsonData.message;
      });
  }
}
