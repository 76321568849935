import { Controller } from '@hotwired/stimulus';
import { useMeta } from 'stimulus-use';

export default class extends Controller {
  static metaNames = ['csrf-token', 'user-id', 'user-name', 'user-email'];

  connect() {
    useMeta(this);
  }

  handleError = (error) => {
    const message = `Error in controller: ${this.identifier}`;
    const context = {
      controller: this.identifier,
      element: this.element,
      user_id: this.userId,
      username: this.userName,
      email: this.userEmail,
    };

    this.application.handleError(error, message, context);
  };

  get csrfToken() {
    return this.csrfTokenMeta;
  }

  get userId() {
    return this.userIdMeta;
  }

  get userName() {
    return this.userNameMeta;
  }

  get userEmail() {
    return this.userEmailMeta;
  }
}
