import MicroModal from 'micromodal';
import ApplicationController from '../application_controller';

// Connects to data-controller="administrators--modal"
export default class extends ApplicationController {
  connect() {
    super.connect();
    this.show();
  }

  show() { // eslint-disable-line class-methods-use-this
    MicroModal.show('modal-window', { disableScroll: true });
  }

  close() { // eslint-disable-line class-methods-use-this
    MicroModal.close('modal-window');
  }

  closeIfSuccess(event) { // eslint-disable-line class-methods-use-this
    if (event.detail.success) {
      MicroModal.close('modal-window');
    }
  }
}
