import TomSelect from 'tom-select';
import ApplicationController from './application_controller';

// Connects to data-controller="multiple-select"
export default class extends ApplicationController {
  static values = {
    plugins: Array,
  };

  connect() {
    super.connect();

    this.newTomSelect();
  }

  newTomSelect() {
    const config = {
      plugins: this.pluginsValue,
    };

    return new TomSelect(this.element, config);
  }
}
