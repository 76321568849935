import ApplicationController from './application_controller';

// Connects to data-controller="tab"
export default class extends ApplicationController {
  static classes = ['activeMenu'];

  connect() {
    super.connect();
  }

  switch({ currentTarget, params: { id } }) {
    const items = this.element.querySelectorAll('.tab-menu-item');
    items.forEach((item) => {
      if (item === currentTarget) {
        item.classList.add('active');
        if (this.hasActiveMenuClass) { item.classList.add(this.activeMenuClasses); }
      } else {
        item.classList.remove('active');
        if (this.hasActiveMenuClass) { item.classList.remove(this.activeMenuClasses); }
      }
    });

    const panels = this.element.querySelectorAll('.tab-panel');
    panels.forEach((panel) => {
      if (panel.id === id) {
        panel.classList.add('active');
      } else {
        panel.classList.remove('active');
      }
    });
  }
}
