import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="custom-file-input"
export default class extends Controller {
  static targets = ['file', 'label'];

  connect() {
    this.fileTarget.addEventListener('change', (event) => {
      this.labelTarget.innerText = event.currentTarget.value;
    });
  }
}
