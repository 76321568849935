import Reveal from 'stimulus-reveal-controller';

export default class extends Reveal {
  toggle(e) {
    super.toggle();

    e.currentTarget.classList.toggle('fa-square-plus');
    e.currentTarget.classList.toggle('fa-square-minus');
  }
}
