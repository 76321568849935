import * as Sentry from '@sentry/browser';
import ApplicationController from './application_controller';

// Connects to data-controller="sentry"
export default class extends ApplicationController {
  connect() {
    super.connect();

    Sentry.setUser({
      id: super.userId,
      username: super.userName,
      email: super.userEmail,
    });
  }
}
