import {
  computePosition, autoUpdate, flip, shift, offset, arrow,
} from '@floating-ui/dom';
import ApplicationController from './application_controller';

// Connects to data-controller="tooltip"
export default class extends ApplicationController {
  static targets = ['trigger', 'body', 'arrow'];

  static values = {
    placement: { type: String, default: 'bottom' },
    offset: { type: Number, default: 5 },
    isShowOnConnect: { type: Boolean, default: false },
  };

  get trigger() {
    return this.isShowOnConnectValue ? this.element : this.triggerTarget;
  }

  get tooltip() {
    return this.bodyTarget;
  }

  connect() {
    super.connect();

    if (this.isShowOnConnectValue) {
      this.show();
    }
  }

  show() {
    this.#show(this.tooltip);
    this.#computeTooltipPosition();
  }

  hide() {
    this._hide(this.tooltip); // eslint-disable-line no-underscore-dangle
  }

  _hide(element) { // eslint-disable-line class-methods-use-this, no-underscore-dangle
    element.style.display = '';
    element.style.zIndex = 'auto';
  }

  #show(element) { // eslint-disable-line class-methods-use-this
    element.style.display = 'block';
    element.style.zIndex = 1;
  }

  #computeTooltipPosition() {
    const middleware = [
      offset(this.offsetValue),
      flip(),
      shift({ padding: 5 }),
    ];

    if (this.hasArrowTarget) {
      middleware.push(arrow({ element: this.arrowTarget }));
    }

    autoUpdate(this.trigger, this.tooltip, () => {
      computePosition(this.trigger, this.tooltip, {
        placement: this.placementValue,
        middleware,
      }).then(({
        x, y, placement, middlewareData,
      }) => {
        Object.assign(this.tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        if (this.hasArrowTarget) {
          this.#computeArrowPosition(placement, middlewareData);
        }
      });
    });
  }

  #computeArrowPosition(placement, middlewareData) {
    const { x: arrowX, y: arrowY } = middlewareData.arrow;
    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    Object.assign(this.arrowTarget.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      [staticSide]: '-4px',
    });
  }
}
