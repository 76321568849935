// How to make this. https://www.youtube.com/watch?v=ReZS6wfh3lo
import { get } from '@rails/request.js';
import ApplicationController from './application_controller';

// Connects to data-controller="select"
export default class extends ApplicationController {
  static targets = ['update'];

  static values = {
    url: String,
    param: String,
  };

  connect() {
    super.connect();
  }

  change(event) {
    const params = new URLSearchParams();
    params.append(this.paramValue, event.target.selectedOptions[0].value);
    params.append('update_element_id', this.updateTarget.id);

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream',
    });
  }
}
