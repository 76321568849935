import ApplicationController from '../application_controller';

// Connects to data-controller="employees--care"
export default class extends ApplicationController {
  static targets = [
    'actionOtherLabel',
    'messageBodyAndPreview',
    'updateSubmit',
    'deliverSubmit',
    'helpText',
  ];

  connect() {
    super.connect();
  }

  switch(event) {
    const { actionKey } = event.params;

    this.switchComponent(actionKey);
    this.switchSubmit(actionKey);
    this.switchHelpText(actionKey);
  }

  hideAllComponents() {
    this.actionOtherLabelTarget.classList.add('d-none');
    this.messageBodyAndPreviewTarget.classList.add('d-none');
  }

  switchComponent(actionKey) {
    this.hideAllComponents();

    if (actionKey === 'other') {
      this.actionOtherLabelTarget.classList.remove('d-none');
    } else if (actionKey === 'message_via_site') {
      this.messageBodyAndPreviewTarget.classList.remove('d-none');
    }
  }

  switchSubmit(actionKey) {
    if (actionKey === 'message_via_site') {
      this.updateSubmitTarget.classList.add('d-none');
      this.deliverSubmitTarget.classList.remove('d-none');
    } else {
      this.updateSubmitTarget.classList.remove('d-none');
      this.deliverSubmitTarget.classList.add('d-none');
    }
  }

  switchHelpText(actionKey) {
    if (actionKey === 'message_via_site') {
      this.helpTextTarget.classList.add('d-none');
    } else {
      this.helpTextTarget.classList.remove('d-none');
    }
  }
}
