import { useClickOutside } from 'stimulus-use';
import TooltipController from '../tooltip_controller';

// Connects to data-controller="tooltips--comment"
export default class extends TooltipController {
  static values = {
    placement: { type: String, default: 'right' },
  };

  connect() {
    super.connect();
    useClickOutside(this);
  }

  clickOutside() {
    super.hide();
  }

  show() {
    this.#hideAll();
    super.show();
  }

  #hideAll() {
    document.querySelectorAll('.tooltip').forEach((tooltip) => {
      this._hide(tooltip); // eslint-disable-line no-underscore-dangle
    });
  }
}
