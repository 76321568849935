import ApplicationController from './application_controller';

// Connects to data-controller="form"
export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  instantSearch() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 400);
  }

  submit() {
    this.element.requestSubmit();
  }
}
