import ApplicationController from './application_controller';

// Connects to data-controller="authentication-code-form"
export default class extends ApplicationController {
  static targets = ['input'];

  connect() {
    super.connect();
    this.focusFirstEmptyInputOrLastInput();
  }

  focusFirstEmptyInputOrLastInput() {
    const element = this.inputTargets.find((input) => input.value === '');

    if (element !== undefined) {
      element.focus();
    } else {
      const lastElement = this.inputTargets[this.inputTargets.length - 1];
      lastElement.focus();
      lastElement.setSelectionRange(lastElement.maxLength, lastElement.maxLength);
    }
  }

  focusNext(event) {
    const { currentTarget } = event;
    const { value, maxLength } = currentTarget;
    const currentIndex = this.inputTargets.indexOf(currentTarget);

    if (value.length === maxLength && currentIndex < this.inputTargets.length - 1) {
      this.inputTargets[currentIndex + 1].focus();
    }
  }

  focusBack(event) {
    if (event.key === 'Backspace') {
      const { target } = event;
      const { value } = target;
      const currentIndex = this.inputTargets.indexOf(target);

      if (value.length === 0 && currentIndex > 0) {
        const prevElement = this.inputTargets[currentIndex - 1];
        prevElement.value = '';
        prevElement.focus();
      }
    }
  }

  pasteEachInput(event) {
    event.preventDefault();

    let text = (event.clipboardData || window.clipboardData).getData('text');
    text = text.toUpperCase();
    const characters = text.split('');

    const { currentTarget } = event;
    const currentIndex = this.inputTargets.indexOf(currentTarget);

    this.inputTargets.slice(currentIndex).forEach((input, index) => {
      if (characters[index]) {
        input.value = characters[index];
      }
    });

    if (this.inputTargets[characters.length]) {
      this.inputTargets[characters.length].focus();
    } else {
      this.inputTargets[this.inputTargets.length - 1].focus();
    }
  }

  removeNonAlphanumeric(event) { // eslint-disable-line class-methods-use-this
    const { currentTarget } = event;
    currentTarget.value = currentTarget.value.replace(/[^0-9a-zA-Z]/g, '').toUpperCase();
  }
}
