import ApplicationController from './application_controller';

// Connects to data-controller="survey-delivery-day"
export default class extends ApplicationController {
  static targets = ['input', 'output'];

  static values = {
    url: String,
  };

  connect() {
    super.connect();
    this.next_delivery_day();
  }

  next_delivery_day() {
    const url = decodeURI(this.urlValue);
    const urlWithParams = url.replace('%days%', this.inputTarget.value);

    fetch(encodeURI(urlWithParams))
      .then((response) => response.json())
      .then((json) => {
        this.outputTarget.innerText = json.message;
      });
  }
}
