import DropzoneController from '../dropzone_controller';

export default class extends DropzoneController {
  connect() {
    super.connect();

    this.dropzone.on('success', (file) => {
      const response = JSON.parse(file.xhr.response);
      document.getElementById('post-thumbnail').src = response.image_url;
    });
  }

  get dropzoneOptions() {
    return {
      url: this.element.action,
      parallelUploads: 1,
      disablePreviews: true,
    };
  }
}
