import { Calendar } from '@fullcalendar/core';
import japanese from '@fullcalendar/core/locales/ja';
import dayGridPlugin from '@fullcalendar/daygrid';
import ApplicationController from './application_controller';

// Connects to data-controller="calendar"
export default class extends ApplicationController {
  static values = {
    events: Array,
  };

  connect() {
    super.connect();
    this.show();
  }

  show() {
    const calendar = new Calendar(this.element, {
      plugins: [dayGridPlugin],
      locale: japanese,
      initialView: 'dayGridMonth',
      events: this.eventsValue,
    });

    calendar.render();
  }
}
