import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="employees--sidebar"
export default class extends Controller {
  open() { /* eslint-disable-line class-methods-use-this */
    document.body.classList.remove('sidebar-closed');
  }

  close() { /* eslint-disable-line class-methods-use-this */
    document.body.classList.add('sidebar-closed');
  }
}
