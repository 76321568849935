import Reveal from 'stimulus-reveal-controller';

// Connects to data-controller="checkbox-reveal"
export default class extends Reveal {
  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'disabled';
  }

  toggle(e) {
    const { checked } = e.currentTarget;

    if (checked) this.show();
    else this.hide();
  }
}
