import ApplicationController from './application_controller';

// Connects to data-controller="fragment-link-highlight"
export default class extends ApplicationController {
  static classes = ['style'];

  connect() {
    super.connect();

    this.addHighlightClass();
  }

  addHighlightClass() {
    if (this.element.children.length === 0) { return; }

    const fragment = window.location.hash;
    if (fragment === '') { return; }

    const target = this.element.querySelector(fragment);
    target.classList.add(...this.styleClasses);
  }
}
