import { useClickOutside } from 'stimulus-use';
import TooltipController from '../tooltip_controller';

// Connects to data-controller="tooltips--dropdown"
export default class extends TooltipController {
  static targets = ['trigger', 'icon', 'menu'];

  get tooltip() {
    return this.menuTarget;
  }

  connect() {
    super.connect();
    useClickOutside(this);
  }

  clickOutside() {
    this.hide();
  }

  show() {
    this.#rotateIcon180deg();
    super.show();
  }

  hide() {
    this.#rotateIcon0deg();
    super.hide();
  }

  #rotateIcon180deg() {
    if (!this.hasIconTarget) return;

    this.iconTarget.classList.add('rotate-180');
    this.iconTarget.classList.remove('rotate-0');
  }

  #rotateIcon0deg() {
    if (!this.hasIconTarget) return;

    this.iconTarget.classList.remove('rotate-180');
    this.iconTarget.classList.add('rotate-0');
  }
}
