import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import VideoTool from '@weekwood/editorjs-video';
import DragDrop from 'editorjs-drag-drop';

import ApplicationController from './application_controller';

// Connects to data-controller="editor"
export default class extends ApplicationController {
  static targets = ['holder', 'content'];

  static values = {
    uploadUrl: String,
  };

  connect() {
    super.connect();

    this.contentEditor = this.initEditor();
    this.element.addEventListener('submit', this.saveEditorData.bind(this));
  }

  initEditor() {
    const editor = new EditorJS({
      holder: this.holderTarget,
      data: this.getInitialContent(),
      placeholder: 'Let`s write an awesome story!',
      tools: {
        header: {
          class: Header,
          config: {
            placeholder: 'Enter a header',
            levels: [2, 3, 4, 5, 6],
            defaultLevel: 2,
          },
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: this.uploadUrlValue,
            },
            additionalRequestHeaders: {
              'X-CSRF-Token': this.csrfToken,
            },
          },
        },
        video: {
          class: VideoTool,
          config: {
            endpoints: {
              byFile: this.uploadUrlValue,
            },
            additionalRequestHeaders: {
              'X-CSRF-Token': this.csrfToken,
            },
            player: {
              controls: true,
              autoplay: false,
            },
          },
        },
      },
      onReady: () => {
        new DragDrop(editor);
      },
    });

    return editor;
  }

  getInitialContent() {
    if (this.hasContentTarget && this.contentTarget.value) {
      return JSON.parse(this.contentTarget.value);
    }
    return {};
  }

  moveNext(event) {
    if (event.isComposing) { return; } // IME入力中は無視

    event.preventDefault();
    this.element.querySelector('.ce-paragraph').focus();
  }

  async saveEditorData(event) {
    event.preventDefault();

    const outputData = await this.contentEditor.save();
    this.contentTarget.value = JSON.stringify(outputData);
    this.element.submit();
  }
}
