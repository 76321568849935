import DropzoneController from '../dropzone_controller';

export default class extends DropzoneController {
  static values = {
    lang: String,
    acceptedFiles: String,
  };

  connect() {
    super.connect();

    this.dropzone.on('success', (file) => {
      const response = JSON.parse(file.xhr.response);
      document.getElementById('js-dropzone-response').classList.remove('d-none');
      document.getElementById('js-dropzone-response-message').innerText = response.message;
    });

    this.dropzone.on('error', (file) => {
      const response = JSON.parse(file.xhr.response);
      document.getElementById('js-dropzone-error').classList.remove('d-none');
      document.getElementById('js-dropzone-error-message').innerText = response.message;
    });
  }

  get dropzoneOptions() {
    return {
      url: this.element.action,
      parallelUploads: 1,
      acceptedFiles: this.acceptedFilesValue,
      dictDefaultMessage: this.dictDefaultMessage,
      dictInvalidFileType: this.dictInvalidFileType,
    };
  }

  get lang() {
    return this.langValue != null ? this.langValue : 'ja';
  }

  get dictDefaultMessage() {
    if (this.lang === 'en') {
      return 'Drop files here to upload';
    }
    return 'ファイルをドラッグ＆ドロップしてしてください。<br>またはここをクリック(タップ)して選択してください。';
  }

  get dictInvalidFileType() {
    if (this.lang === 'en') {
      return 'You can’t upload files of this type.';
    }
    return 'このタイプのファイルはアップロードできません。';
  }
}
