import ScrollHint from 'scroll-hint';
import ApplicationController from './application_controller';

// Connects to data-controller="scroll-hint"
export default class extends ApplicationController {
  connect() {
    super.connect();
    this.show();
  }

  show() {
    const selector = this.element.className.split(' ').join('.');
    return new ScrollHint(`.${selector}`);
  }
}
