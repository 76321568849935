import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="image"
export default class extends Controller {
  static targets = [
    'input', 'output', 'label',
  ];

  preview() {
    if (!(this.inputFiles && this.inputFiles[0])) { return; }

    const reader = new FileReader();

    reader.onload = () => {
      this.outputTarget.src = reader.result;

      if (this.hasLabelTarget) {
        this.labelTarget.innerText = this.inputFiles[0].name;
      }
    };

    reader.readAsDataURL(this.inputFiles[0]);
  }

  get inputFiles() {
    return this.inputTarget.files;
  }
}
